import React, { useCallback, useState } from 'react';
import Layout from '../../components/layout/Layout';
import {Title} from '../../components/typography';
import * as S from './Productions.css';
import Head from '../../components/Head';
import {ProductionsTumbContent, contentList} from './components/ProductionsTextContent';

const MAX_CAROUSEL_PAGE = Math.ceil(contentList.length / 4);
const ITEMS_PER_PAGE = 4;

const Productions = ({location}) => {
  const [currentCarouselPage, setCurrentCarouselPage ] = useState(1);
  const Thumbnail = (ThumbContent: ProductionsTumbContent) => {

    return (
      <S.ThumbBox key={ThumbContent.link} productionBy={S.productionTypes[ThumbContent.tag]}>
        <S.ThumbLink href={ThumbContent.link} target='_blank' rel="noreferrer"></S.ThumbLink>
        <S.ThumbTag>
          {ThumbContent.tag}
        </S.ThumbTag>
        <S.ThumbDate>
          {ThumbContent.date}
        </S.ThumbDate>
        <S.ThumbTitle>
          {ThumbContent.title}
        </S.ThumbTitle>
        <S.ThumbParagraph>
          {ThumbContent.paragraph}
        </S.ThumbParagraph>
      </S.ThumbBox>
    );
  }

  const carousel = useCallback((TumbContentList: ProductionsTumbContent[]) => {
    const thumbList: React.JSX.Element[] = [];
    const slideBegin = (ITEMS_PER_PAGE * currentCarouselPage) - 4;
    const slideEnd = ITEMS_PER_PAGE * currentCarouselPage;

    for (let i = slideBegin; i < slideEnd; i++) {
      if(i >= TumbContentList.length)
        continue;

      const thumbnailContent = TumbContentList[i];

      thumbList.push(
        <Thumbnail 
          key={i} 
          date={thumbnailContent.date}
          tag={thumbnailContent.tag}
          link={thumbnailContent.link}
          title={thumbnailContent.title}
          paragraph={thumbnailContent.paragraph}
        />
      )
    }

    return (<>{thumbList.map(i => i)}</>)
  }, [currentCarouselPage])

  const nextCarouselSlide = () => {
    const nextPage = currentCarouselPage + 1;
    console.log("nextCarouselSlide")

    if(nextPage <= MAX_CAROUSEL_PAGE){
      setCurrentCarouselPage(nextPage)
    } else {
      setCurrentCarouselPage(1)
    }
  }

  const prevCarouselSlide = () => {
    const prevPage = currentCarouselPage - 1;

    console.log("prevCarouselSlide")

    if(prevPage >= 1){
      setCurrentCarouselPage(prevPage)
    } else {
      setCurrentCarouselPage(1)
    }
  }

  return (
    <Layout location={location}>
      <Head siteTitle="Sobre o IEPS"></Head>
      <S.Block>
        <S.About>
          <S.HeroBlock>
            <Title>Produções</Title>
              <S.SubTitle>
                Curadoria de produções do IEPS e matérias realizadas <br/>
                utilizando dados do IEPS Data
              </S.SubTitle>
              <S.HeroContentContainer>
                {
                  carousel(contentList)
                }
              </S.HeroContentContainer>
              <S.SlideControl>
                <S.PaginationButton onClick={prevCarouselSlide}>Anterior</S.PaginationButton>
                <S.PaginationButton onClick={nextCarouselSlide}>Próximo</S.PaginationButton>
              </S.SlideControl>
          </S.HeroBlock>
        </S.About>
      </S.Block>
    </Layout>
  );
};

export default Productions;
