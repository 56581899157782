export type ProductionsTumbContent = {
  link: string;
  tag: string;
  date: string;
  title: string;
  paragraph: string;
}

export const contentList : ProductionsTumbContent[] = [
  {
    link: "https://ieps.org.br/boletim-ieps-data-04-2024/",
    tag: "IEPS Data",
    date: "Boletim IEPS Data n.4/2024",
    title: "Saúde nas Capitais",
    paragraph: "O Boletim IEPS Data n. 4 apresenta uma análise da saúde local nas 27 capitais do Brasil em três dimensões: atenção básica, mortalidade e morbidade, e despesas com saúde. A análise considera dados do último ano disponível (2023) e do primeiro ano registrado pelo IEPS Data (2010), com foco nos valores atuais e na evolução dos indicadores de administração local que impactam diretamente a saúde municipal."
  },
  {
    link: "https://ieps.org.br/nota-tecnica-34/",
    tag: "FLUXSUS",
    date: "Nota Técnica n. 34",
    title: "Análise dos fluxos de hospitalizações entre Regiões de Saúde",
    paragraph: "A Nota Técnica n. 34 traz análises dos fluxos de pacientes para hospitalizações entre diferentes Regiões de Saúde, como objetivo de proporcionar uma melhor compreensão do processo de regionalização no âmbito do Sistema Único de Saúde (SUS)."
  },
  {
    link: "https://ieps.org.br/boletim-ieps-data-03-2023/",
    tag: "IEPS Data",
    date: "Boletim IEPS Data n. 3/2023",
    title: "Recursos",
    paragraph: "O Boletim IEPS Data n. 3 analisa a evolução dos principais indicadores de Recursos de Saúde (físicos e humanos) nos estados e regiões de saúde brasileiros. Ele faz parte de uma série de boletins IEPS Data, composta também pelos boletins de Mortalidade e Morbidade e Atenção Básica."
  },
  {
    link: "https://ieps.org.br/boletim-ieps-data-02-2023/",
    tag: "IEPS Data",
    date: "Boletim IEPS Data n. 2/2023",
    title: "Atenção Básica",
    paragraph: "O Boletim IEPS Data n. 2 analisa a evolução dos principais indicadores da Atenção Básica nos municípios e estados brasileiros. Ele faz parte de uma série de boletins IEPS Data, composta também pelos boletins de Mortalidade e Morbidade, de Recursos e de Despesas com Saúde."
  },
  {
    link: "https://ieps.org.br/boletim-ieps-data-01-2023/",
    tag: "IEPS Data",
    date: "Boletim IEPS Data n. 1/2023",
    title: "Mortalidade e Morbidade",
    paragraph: "RESUMO – O Boletim IEPS Data n. 1 analisa a evolução dos principais indicadores de mortalidade e morbidade nos municípios e estados brasileiros. Ele faz parte de uma série de boletins IEPS Data, composta também pelos boletins de Atenção Básica, de Recursos e de Despesas com Saúde."
  },
  {
    link: "https://ieps.org.br/nota-tecnica-28/",
    tag: "IEPS Data",
    date: "Nota Técnica n. 28",
    title: "A Saúde dos Estados em Perspectiva Comparada: Uma Análise dos Indicadores Estaduais do Portal IEPS Data",
    paragraph: "RESUMO – O IEPS Data disponibiliza indicadores de saúde relevantes em uma interface de fácil acesso, com ferramentas de visualizações e download de dados."
  },
  {
    link: "https://ieps.org.br/nota-tecnica-26/",
    tag: "IEPS Data",
    date: "Nota Técnica n. 26",
    title: "O Portal IEPS Data como ferramenta de análise de dados de saúde: uma aplicação aos casos de Recife e Ceará",
    paragraph: "RESUMO EXECUTIVO – Os dados de saúde no Brasil são ricos e granulares. Os sistemas de informação do Ministério da Saúde contêm dados sobre óbitos, nascimentos, hospitalizações, atendimentos"
  }
]